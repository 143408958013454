import farmer from  "./image/avatar2.png";
import tree from    "./image/tree.jpeg"
import plant from "./image/blacktree.jpeg"
import dung from    "./image/dung.jpg"
import pest from  "./image/avatar2.png"
import max from "./image/max.jpg"
import har from "./image/har.jpeg"
import blanch from "./image/har.jpeg"
import sun from "./image/sun.jpeg"
import india from "./image/india.jpg"
const Card1Data = [
    {
        img:farmer,
        title:"Farmer picture",
        name:"Ramu Das"
    },
    {
        img:tree,
        title:"Farmer name and place",
        name:"Valiyaparambil Farm"
    },
    {
        img:plant,
        title:"Spice variety name",
        name: "Karimunda"
    },
    {
        img:dung,
        title:"Fertilizer used",
        name:"Cow Dung"
    }
    ,{
        img:pest,
        title:"Pesticides",
        name:"NO Pesticides"

    },
    {
        img:har,
        title:"Month of Harvesting",
        name:"February 2023"

    }
 ]

 const Card2Data = [
    {
        img:max,
        title:"Month Of Collection",
        name:"February 2023"
    },
    {
        img:blanch,
        title:"Blanching Process",
        name:"February 2023"
    },
    {
        img:sun,
        title:"Sun Drying Process",
        name:"February 2023"
    },
    {
        img:india,
        title:"Month of Storage",
        name:"February 2023"

    }
   
 ]
 export {Card1Data,Card2Data};