import React from 'react';
import headerImage from "./image/sun.jpeg";
import logo from "./image/spice-fiction-logo.png"


const Header = () => {
  
    return (
      <div className='w-full h-52 bg-green-600  border-none'>
         <img className="absolute w-40 h-30 top-4 left-24 bg-white  " src={logo} alt="Spice Fiction Logo" />
       
        
          <img className="w-full max-h-48 object-none rounded-b-2xl" src={"https://images.unsplash.com/photo-1649951806971-ad0e00408773?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=881&q=80"} alt="Spice Fiction Logo" />
       
        <h1 className="text-white text-center text-3xl absolute top-28 left-20 font-bold">BLACK PEPPER</h1>
      </div>
    );
  
}

export default Header;
