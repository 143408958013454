import React, { useState, useEffect, use } from "react";
import Header from "./Header";
import { Card1Data, Card2Data } from "./data";
import { db } from "./firebase";
import { useParams } from "react-router-dom";
import farmer from "./image/avatar2.png";
import tree from "./image/tree.jpeg";
import plant from "./image/blacktree.jpeg";
import dung from "./image/dung.jpg";
import pest from "./image/avatar2.png";
import max from "./image/max.jpg";
import har from "./image/har.jpeg";
import blanch from "./image/har.jpeg";
import sun from "./image/sun.jpeg";
import india from "./image/india.jpg";

function Farmer() {
  const { id } = useParams();
  const [hash, setHash] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    db.collection("mern").onSnapshot((snapshot) => {
      setHash(snapshot.docs.map((doc) => doc.data()));
    });
  }, []);

  console.log("test", hash);
  useEffect(() => {
    const matchedObject = hash.find((obj) => obj.id === parseInt(id));
    console.log(matchedObject, "matched object");
    if (matchedObject) {
      // Store the matched object in the new variable
      setData(matchedObject);
    }
  }, [hash]);

  console.log(data, "data @@@@@@");
  const [data1, setData1] = useState(Card1Data);
  const [data2, setData2] = useState(Card2Data);
  return (
    <div className="flex justify-center items-center w-full h-full bg-green-600 ">
      {/* <Header /> */}

      <div className="flex flex-col w-full h-full items-center p-5">
        <div className="bg-white w-[90%] md:w-[45%] rounded-lg flex justify-center flex-col  m-5  shadow-md shadow-black">
          <h1 className="text-lg font-bold mx-auto mt-3 underline text-green-800">
            A: Farmer Level
          </h1>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={farmer}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Farmer Name"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {data?.Farmer}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={plant}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Farm Name and Place"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {data?.["Farm "] || data?.Farm}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={plant}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Spice Variety Name"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {data?.Variety}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={dung}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Fertilizers used"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {data?.Fertilizer}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={pest}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Pesticides"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {"No Pesticides used for this Product"}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={har}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Month Of Harvest"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {"February 2023"}
              </h2>
            </div>
          </div>
        </div>
        <div className="bg-white  w-[90%] md:w-[45%]  rounded-lg flex justify-center flex-col shadow-md shadow-black ">
          <h1 className="text-lg font-bold mx-auto mt-3 underline text-green-800">
            B: The Spice Fiction Level
          </h1>
          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={max}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Month Of Collection"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {"February 2023"}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={blanch}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Blanching Process"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {"February 2023"}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={sun}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Sun Drying Process"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {"February 2023"}
              </h2>
            </div>
          </div>

          <div className="p-2 px-3 flex justify-evenly">
            <div className="flex justify-center w-[40%]  sm:w-[30%] ">
              <div className="p-1 w-50 h-50 rounded-full border-green-700 border-2 border-dotted">
                <img
                  src={india}
                  alt="farmer"
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
            <div className=" flex items-center flex-col w-[60%]   sm:w-[70%] justify-center">
              <h1 className="text-xs font-bold sm:text-base  text-yellow-600">
                {"Month of Storage"}
              </h1>
              <h2 className="text-xs sm:text-base font-bold  text-green-700 text-center">
                {"February 2023"}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Farmer;
