import React, { useState } from "react";
import Header from "./Header";

import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Farmer from "./Farmer";

function App() {


  return (
    <>
    <Header/>
      <Routes>
        <Route path="/farmer">
          <Route path="/farmer/:id" element={<Farmer/>}/>
          </Route>
    
      </Routes>
    </>
  );
}

export default App;
