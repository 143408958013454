
import firebase from "firebase";



const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBqoh7d8a8QifI4amtAclsYQO2XtmFSHdY",
  authDomain: "pice-strace.firebaseapp.com",
  projectId: "pice-strace",
  storageBucket: "pice-strace.appspot.com",
  messagingSenderId: "492977184160",
  appId: "1:492977184160:web:9f68992560efd2dcec18c5",
});



const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };